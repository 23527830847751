import React from 'react';
import _ from 'lodash';
import ProGalleryFullscreenMock from './ProGalleryFullscreenMock';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import '../../../styles/WixStyles.scss';
import './FullscreenWrapper.scss';
import VIEW_MODE from 'pro-gallery/dist/src/common/constants/viewMode';

export default class ProFullscreenWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      container: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      proFullscreenLoaded: false,
    };

    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.resize = this.resize.bind(this);
    this.debouncedResizeEvent = _.debounce(this.resize, 100);
    this.lastPageScroll = -1;
    this.FullscreenElement = null;
  }

  componentWillMount() {
    window.addEventListener('fullscreenchange', this.onFullscreenChange);
    window.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
    window.addEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.addEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.addEventListener('resize', this.debouncedResizeEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('fullscreenchange', this.onFullscreenChange);
    window.removeEventListener(
      'webkitfullscreenchange',
      this.onFullscreenChange,
    );
    window.removeEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.removeEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.removeEventListener('resize', this.debouncedResizeEvent);
  }

  componentDidMount() {
    import(/* webpackChunkName: "pro-fullscreen-renderer" */ '@wix/pro-fullscreen-renderer').then(module => {
      this.FullscreenElement = module.ProFullscreen;
      this.setState({ proFullscreenLoaded: true });
    });
  }

  resize() {
    this.setState({
      container: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  onFullscreenChange = () => {
    let container = {
      width: window.screen.width,
      height: window.screen.height,
    };

    if (
      !window.document.fullscreenElement &&
      !window.document.webkitIsFullScreen &&
      !window.document.mozFullScreen &&
      !window.document.msFullscreenElement
    ) {
      container = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    this.setState({ container });
  };

  blockParentScroll(shouldBlock) {
    const stopScrollClass = 'pro-gallery-stop-scroll-for-fullscreen';
    const pageHtml = window.document.getElementsByTagName('html')[0];
    const classList = pageHtml && pageHtml.classList;

    try {
      if (shouldBlock && !this.parentScrollIsBlocked) {
        this.lastPageScroll = window.scrollY;
        this.parentScrollIsBlocked = true;
        classList.add(stopScrollClass);
      } else if (!shouldBlock && this.parentScrollIsBlocked) {
        this.parentScrollIsBlocked = false;
        classList.remove(stopScrollClass);
        if (this.lastPageScroll >= 0) {
          this.lastPageScroll = -1;
          window.scrollTo(0, this.lastPageScroll);
        }
      }
    } catch (e) {
      console.log('Cannot stop parent scroll', e);
    }
  }

  render() {
    const { fullscreenIdx, items, styleParams, totalItemsCount } = this.props;
    let ProFullscreenElement;
    if (this.state.proFullscreenLoaded && this.FullscreenElement) {
      ProFullscreenElement = this.FullscreenElement;
    } else {
      ProFullscreenElement = null;
    }
    if (fullscreenIdx >= 0) {
      // this.blockParentScroll(true);
      if (ProFullscreenElement) {
        return (
          <div
            className="pro-fullscreen-wrapper"
            style={{
              opacity: this.props.fullscreenAnimating ? 0 : 1,
              transition: 'opacity .8s ease',
              overflow: 'hidden',
            }}
          >
            <ProFullscreenElement
              items={items}
              initialIdx={fullscreenIdx}
              totalItemsCount={totalItemsCount}
              container={this.state.container}
              locale={'en'}
              deviceType={'desktop'}
              styles={styleParams}
              galleryId={this.props.galleryId}
              viewMode={this.props.viewMode}
              noFollowForSEO={this.props.noFollowForSEO}
              // customSidebarRenderer={props => {
              //   return <div>customSidebarRenderer {props.idx}</div>;
              // }}
              eventsListener={this.props.eventsListener}
              itemsLoveData={this.props.itemsLoveData}
            />
          </div>
        );
      } else if (this.props.viewMode === VIEW_MODE.SEO) {
        return (
          <div
            className="pro-fullscreen-wrapper"
            style={{
              opacity: this.props.fullscreenAnimating ? 0 : 1,
              transition: 'opacity .8s ease',
              overflow: 'hidden',
              backgroundColor: 'white',
            }}
          >
            <ProGalleryFullscreenMock
              items={items}
              totalItemsCount={totalItemsCount}
              container={this.state.container}
              locale={'en'}
              galleryId={this.props.galleryId}
              viewMode={this.props.viewMode}
              noFollowForSEO={this.props.noFollowForSEO}
              eventsListener={this.props.eventsListener}
              itemsLoveData={this.props.itemsLoveData}
              resizeMediaUrl={this.props.resizeMediaUrl}
              domId={this.props.domId}
              allowSSR={true}
            />
          </div>
        );
      } else {
        return null;
      }
    } else {
      // this.blockParentScroll(false);
      return null;
    }
  }
}
